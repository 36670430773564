import React from "react"
import ImageContainer from "../components/imageContainer"
import OvalMobile from "../images/ovalMobile.png"
import BgHome1 from "../images/interior3x.png"
import BgHome2 from "../images/interior2-2x.png"
import Woman2 from "../images/woman2-3x.png"
import Woman1 from "../images/woman1-3x.png"
import People1 from "../images/People1-3x.png"
import People3 from "../images/People3-3x.png"
import People2 from "../images/People2-3x.png"
import Woman3 from "../images/woman3-3x.png"



const BghomeA = {
  div1: "flex  pl-5 justify-end relative lg:w-97 xl:w-100",//w-7/12
  div2: "w-9/12 h-full self-end flex justify-between z-30  absolute",
  divImg1: "w-5/12 pl-8 z-20 static self-end",
  img1: {
    src: People1,
    alt: "home-people-1",
    className: "w-full object-cover h-auto ",
  },
  div3: "w-11/12 pl-5 pr-8 self-end  self-end  absolute flex justify-end",
  divimg2: "w-7/12",
  img2: {
    src: Woman2,
    alt: "home-woman-2",
    className: "w-full object-cover h-auto",
  },
  divBg: "w-full pb-3",
  imgBg: {
    src: BgHome1,
    alt: "home-bg-1",
    className: "w-full object-cover object-left-bottom h-auto",
  },
}

const BghomeB = {
  div1: "flex pl-5 justify-end relative lg:w-97 xl:w-100",
  div2: "w-9/12 h-full self-end flex justify-between z-30  absolute",
  divImg1: "w-5/12 pl-8 z-20 static self-end",
  img1: {
    src: People1,
    alt: "home-people-1",
    className: "w-full object-cover h-auto ",
  },
  div3: "w-10/12 pl-16 pr-4 self-end self-end  absolute flex justify-end",
  divimg2: "w-6/12",
  img2: {
    src: Woman1,
    alt: "home-woman-1",
    className: "w-full object-cover h-auto",
  },
  divBg: "w-full pb-3",
  imgBg: {
    src: BgHome1,
    alt: "home-bg-1",
    className: "w-full object-cover object-left-bottom h-auto",
  },
}

const BghomeC = {
  div1: "flex  pl-5 justify-end relative lg:w-98 xl:w-101",
  div2: " w-8/12 h-full self-end flex justify-between z-30  absolute",
  divImg1: "w-5/12 pl-6 z-20 static self-end",
  img1: {
    src: Woman3,
    alt: "home-Woman-3",
    className: "w-full object-cover h-auto ",
  },
  div3: "w-11/12 pr-12 self-end self-end  absolute flex justify-end",
  divimg2: "w-6/12 mr-2",
  img2: {
    src: People3,
    alt: "home-people-3",
    className: "w-full object-cover h-auto",
  },
  divBg: "w-11/12 pl-12 pb-10",
  imgBg: {
    src: BgHome1,
    alt: "home-bg-1",
    className: "w-full object-cover object-left-bottom h-auto",
  },
}

const BghomeD = {
  div1: "flex  pl-4 justify-end relative lg:w-97 xl:w-100", //max-w-screen-xl
  div2: "w-9/12 pl-5 h-full self-end flex justify-between z-30 absolute",
  divImg1: "w-5/12 pl-5 pr-3  z-20 static self-end",
  img1: {
    src: People2,
    alt: "home-People3-3",
    className: "w-full object-cover h-auto ",
  },
  div3: "w-10/12 self-end self-end absolute flex justify-end",
  divimg2: "w-7/12 pr-6 pl-5 mr-4",
  img2: {
    src: People3,
    alt: "home-people-3",
    className: "w-full object-cover h-auto",
  },
  divBg: "w-full pl-3 pb-8",
  imgBg: {
    src: BgHome2,
    alt: "home-bg-2",
    className: "w-full object-cover object-left-bottom h-auto",
  },
}

const BgHomeTablet = {
  div1: "flex sm:max-w-sm justify-end relative md:max-w-lg  md:pl-6",
  div2: "w-9/12 pl-5 h-full self-end flex justify-between z-30 absolute",
  divImg1: "w-5/12  pl-2 pr-1  z-20 static self-end",
  img1: {
    src: People2,
    alt: "home-People3-3",
    className: "w-full object-cover h-auto",
  },
  div3: "w-10/12 self-end self-end absolute flex justify-end",
  divimg2: "w-7/12 pr-3 pl-2 mr-2",
  img2: {
    src: People3,
    alt: "home-people-3",
    className: "w-full object-cover h-auto",
  },
  divBg: "w-full mb-8",
  imgBg: {
    src: BgHome2,
    alt: "home-bg-2",
    className: "w-full object-cover object-left-bottom h-auto",
  },
}

const BgHomeTabletShow = () => (
  <div className="hidden sm:block sm:w-full sm:flex sm:justify-end absolute lg:hidden ">
    <div className={`${BgHomeTablet.div1}`}>
      <div className={`${BgHomeTablet.div2}`}>
        <ImageContainer
          container={`${BgHomeTablet.divImg1}`}
          src={BgHomeTablet.img1.src}
          alt={`${BgHomeTablet.img1.alt}`}
          className={`${BgHomeTablet.img1.className}`}
        />

        <div className={`${BgHomeTablet.div3}`}>
          <ImageContainer
            container={`${BgHomeTablet.divimg2}`}
            src={BgHomeTablet.img2.src}
            alt={`${BgHomeTablet.img2.alt}`}
            className={`${BgHomeTablet.img2.className}`}
          />
        </div>
      </div>

      <ImageContainer
        container={`${BgHomeTablet.divBg}`}
        src={BgHomeTablet.imgBg.src}
        alt={`${BgHomeTablet.imgBg.alt}`}
        className={`${BgHomeTablet.imgBg.className}`}
      />
    </div>
  </div>
)

const BgHomeMobile = () => (
  <div className="w-full flex justify-end absolute sm:hidden">
    <ImageContainer container="w-48 pl-4" src={OvalMobile} alt="" />
    <ImageContainer
      container="absolute w-32 mt-32 pr-5 transform scale-x-inv"
      src={Woman3}
      alt=""
      className=""
    />
  </div>
)

const HomeBackground = () => {
  const BgRender = [BghomeA, BghomeB, BghomeC, BghomeD]
  let sel = Math.floor(Math.random() * (4 - 0)) + 0
  //let sel=2
  return (
    <div className="W-full">
      <BgHomeMobile/>
      <BgHomeTabletShow />
      <div className="hidden lg:block lg:w-full lg:flex lg:justify-end absolute ">
        <div className={`${BgRender[sel].div1}`}>
          <div className={`${BgRender[sel].div2}`}>
            <ImageContainer
              container={`${BgRender[sel].divImg1}`}
              src={BgRender[sel].img1.src}
              alt={`${BgRender[sel].img1.alt}`}
              className={`${BgRender[sel].img1.className}`}
            />

            <div className={`${BgRender[sel].div3}`}>
              <ImageContainer
                container={`${BgRender[sel].divimg2}`}
                src={BgRender[sel].img2.src}
                alt={`${BgRender[sel].img2.alt}`}
                className={`${BgRender[sel].img2.className}`}
              />
            </div>
          </div>

          <ImageContainer
            container={`${BgRender[sel].divBg}`}
            src={BgRender[sel].imgBg.src}
            alt={`${BgRender[sel].imgBg.alt}`}
            className={`${BgRender[sel].imgBg.className}`}
          />
        </div>
      </div>
    </div>
  )
}

export default HomeBackground