import React from "react"
import ImageContainer from "../components/imageContainer"
import HomeBackground from "../components/homeBackground"
import CellphoneHome from "../images/cellphoneHome2.png"
import Corporate from "../images/laptopWorkingDesktop.jpg"
import CorporateTablet from "../images/laptopWorking.jpg"
import Layout from "../components/layout"
import Blogo from "../images/b.png"
import ButtonHb from "../components/buttonHB"
import { Link } from "gatsby"

const TwoComponentLIST = ({ titleA, contentA, titleB, contentB }) => (
  <div
    className={`${Style.CompanyDescriptionTwoContainer.all} ${Style.CompanyDescriptionTwoContainer.small} ${Style.CompanyDescriptionTwoContainer.large}`}
  >
    <div
      className={`${Style.CompanyDescriptionTwoContainerList.all} ${Style.CompanyDescriptionTwoContainerList.small} ${Style.CompanyDescriptionTwoContainerList.large}`}
    >
      <div
        className={`${Style.CompanyDescriptionTwoDeco.all} ${Style.CompanyDescriptionTwoDeco.small} ${Style.CompanyDescriptionTwoDeco.large}`}
      />
      <div
        className={`${Style.CompanyDescriptionTwoContainerText.all} ${Style.CompanyDescriptionTwoContainerText.small} ${Style.CompanyDescriptionTwoContainerText.large}`}
      >
        <h3
          className={`${Style.CompanyDescriptionTwoTitle.all} ${Style.CompanyDescriptionTwoTitle.small}  ${Style.CompanyDescriptionTwoTitle.large}`}
        >
          {titleA}
        </h3>
        <p
          className={`${Style.CompanyDescriptionTwoText.all} ${Style.CompanyDescriptionTwoText.small} ${Style.CompanyDescriptionTwoText.large}`}
        >
          {contentA}
        </p>
      </div>
    </div>
    <div
      className={`${Style.CompanyDescriptionTwoContainerList.all} ${Style.CompanyDescriptionTwoContainerList.small} ${Style.CompanyDescriptionTwoContainerList.large}`}
    >
      <div
        className={`${Style.CompanyDescriptionTwoDeco.all} ${Style.CompanyDescriptionTwoDeco.small} ${Style.CompanyDescriptionTwoDeco.large}`}
      />
      <div className="w-full">
        <h3
          className={`${Style.CompanyDescriptionTwoTitle.all} ${Style.CompanyDescriptionTwoTitle.small}  ${Style.CompanyDescriptionTwoTitle.large}`}
        >
          {titleB}
        </h3>
        <p
          className={`${Style.CompanyDescriptionTwoText.all} ${Style.CompanyDescriptionTwoText.small} ${Style.CompanyDescriptionTwoText.large}`}
        >
          {contentB}
        </p>
      </div>
    </div>
  </div>
)

const Style = {
  HomeContainer: {
    all: " z-40",
  },
  SectionTitle: {
    all: "container relative",
    small: "px-5 sm:px-6 md:px-6",
    large: "lg:px-24 xl:px-20",
  },
  ContainerTitle: {
    all: "",
    small:
      "pt-40 w-9/12 pr-1   sm:w-7/12 sm:pt-24 sm:pr-16   md:w-5/12 md:pr-2 md:pt-32",
    large: "lg:w-7/12 lg:pt-40 lg:pr-10 xl:w-7/12 xl:pr-16",
  },
  Title: {
    all: "frk-bold  text-primary",
    small: "text-2xl sm:text-3xl md:text-3xl md:mt-4 md:mb-2",
    large: "lg:text-5xl lg:mt-0 lg:mb-0  xl:text-6xl xl:pt-5",
  },
  AssociationSection: {
    all: "container",
    small:
      "px-5 mb-12 pt-40 sm:px-6 sm:flex sm:w-full sm:items-center sm:pt-32 sm:mb-20   md:pt-40   md:px-6 md:mb-20",
    large: "lg:px-24 lg:mt-32 lg:py-16  xl:px-20",
  },
  ImageContainer: {
    small: "w-full md:w-8/12 sm:hidden",
    large: "lg:block lg:pr-0 lg:w-6/12",
  },
  ImageContainerTablet: {
    small: "hidden sm:block sm:w-9/12 sm:pr-8  md:w-7/12 md:pr-6",
    large: "lg:hidden lg:pr-0 lg:w-6/12",
  },
  AssociationSectionTextContainer: {
    all: "text-primary frk-regular",
    small: "w-11/12 md:mx-auto md:w-8/12",
    large: "lg:w-6/12 lg:pl-10",
  },
  AssociationSectionTitle: {
    all: " text-primary frk-bold ",
    small: "mt-6 text-2xl sm:text-3xl sm:mt-4 md:mt-2 ",
    large: " lg:mt-0 xl:text-5xl",
  },
  AssociationSectionText: {
    all: "text-secondary ",
    small: "text-base my-5 sm:my-4 md:text-lg",
    large: "",
  },
  ListContainer: {
    all: "list-disc text-secondary",
    small: "pl-5 text-sm md:text-lg",
    large: "",
  },
  ListText: {
    all: "my-4 sm:my-2",
  },
  HoverSpecialPagesLink: {
    all: " border-b  border-primary hover:bg-primary hover:text-white",
  },
  CompanySection: {
    all: "w-full bg-primary py-8 ", //
  },
  CompanyDescriptionContainer: {
    all: "container",
    small: "px-5  sm:px-6 ",
    large: "lg:px-24 xl:px-20",
  },
  CompanyDescriptionTitleContainer: {
    all: "w-full",
  },
  CompanyDescriptionTitle: {
    all: "text-aqua frk-bold",
    small: "text-2xl sm:text-3xl",
    large: "lg:text-3xl xl:text-4xl",
  },
  CompanyDescriptionListContainer: {
    all: "w-full",
    small: "",
    large: "lg:pt-5",
  },
  CompanyDescriptionTwoContainer: {
    all: "w-full",
    small: "lg:flex sm:mb-5",
    large: "",
  },
  CompanyDescriptionTwoContainerList: {
    all: "px-0",
    small: "w-full flex my-5",
    large: "lg:my-0 lg:w-1/2 ",
  },
  CompanyDescriptionTwoDeco: {
    all: "bg-aqua w-2 mr-5",
  },
  CompanyDescriptionTwoContainerText: {
    all: "w-10/12",
  },
  CompanyDescriptionTwoTitle: {
    all: "frk-bold text-white",
    small: "text-lg  mb-3 sm:text-2xl",
    large: "lg:text-xl xl:text-2xl",
  },
  CompanyDescriptionTwoText: {
    all: "text-white frk-regular",
    small: "text-base mt-2 sm:mt-0 sm:text-lg",
    large: "",
  },
  AppSectionContainer: {
    all: "container",
    small: "pt-10 px-5 sm:px-6 sm:flex sm:justify-between sm:pt-16",
    large: "lg:px-24 xl:pt-0 xl:px-20 ",
  },
  AppSectionTitleContainer: {
    all: "",
    small: "sm:w-6/12 sm:mt-12 ",
    large: "lg:mt-16 xl:mt-0 xl:self-center lg:w-7/12",
  },
  AppSectionTitle: {
    all: "text-primary frk-bold mx-auto",
    small: "text-2xl md:text-3xl md:mx-0   ",
    large: "lg:w-10/12  lg:text-4xl  xl:text-5xl", //lg:text-4xl xl:text-5xl
  },
  AppSectionTextContainer: {
    all: "pt-0",
    large: "xl:w-full xl:flex lg:justify-between  xl:mt-4",
  },
  AppSectionTextContainerA: {
    all: "text-secondary",
    small: "w-full pt-5 pr-4 sm:pt-4 sm:pr-0 sm:w-10/12 ",
    large: "xl:pt-0 xl:w-6/12  xl:pr-16 ",
  },
  AppSectionSubtitle: {
    all: "mb-4 ",
    small: "text-lg frk-regular",
    large: "lg:mt-0",
  },
  HoverWordLink: {
    all: "border-b  border-primary hover:bg-primary hover:text-white",
  },
  AppSectionText: {
    small: "frk-regular text-lg  mt-5 sm:mt-0  ",
    large: "",
  },
  AppImg: {
    small: "w-full my-12 sm:w-65 md:pl-0 sm:mt-0",
    large: "xl:ml-12  xl:my-6",
  },
  ImgClassName: {
    all: "",//w-full h-auto object-cover object-left-top xl:h-97
  },
  LastSection: {
    all: "w-full bg-tertiary",
    small: "", //mt-8 sm:mt-16
    large: "",
  },
  LastSectionContainer: {
    all: "container",
    small: " px-5 sm:w-full sm:px-6 sm:flex sm:justify-between  md:px-8",
    large: "lg:px-24 xl:px-20",
  },
  LastSectionTextContainer: {
    all: "text-primary ",
    small: "py-8 sm:w-9/12 md:w-9/12 md:py-12 ",
    large: "lg:w-8/12 lg:self-center lg:py-16 xl:w-7/12 xl:py-24 ",
  },
  LastSectionTitle: {
    all: "frk-bold",
    small: "text-3xl leading-snug mb-6 md:text-4xl",
    large: "lg:mb-0 lg:text-3xl xl:text-4xl",
  },
  LastSectionButton: {
    all: "px-4 lg:w-3/12 lg:mt-5  lg:flex lg:justify-around",
  },
  LastSectionImage: {
    all: "",
    small: "hidden sm:block sm:w-3/12",
    large: "lg:w-3/12 lg:mb-10 xl:",
  },
}

const IndexPage = () => {
  let sel = 0
  return (
    <Layout title="Home" link="/">
      <HomeBackground sel={sel} />
      <main className={`${Style.HomeContainer.all}`}>
        <section
          className={`${Style.SectionTitle.all} ${Style.SectionTitle.small} ${Style.SectionTitle.large}`}
        >
          <div
            className={`${Style.ContainerTitle.all} ${Style.ContainerTitle.small} ${Style.ContainerTitle.large}`}
          >
            <h1
              className={`${Style.Title.all} ${Style.Title.small} ${Style.Title.large}`}
            >
              Empowering companies and their digital product strategies
            </h1>
          </div>
        </section>
        <section
          className={`${Style.AssociationSection.all} ${Style.AssociationSection.small} ${Style.AssociationSection.large} `}
        >
          <ImageContainer
            container={`${Style.ImageContainer.small} ${Style.ImageContainer.large}`}
            src={Corporate}
            alt="home-bg-2"
          />
          <ImageContainer
            container={`${Style.ImageContainerTablet.small} ${Style.ImageContainerTablet.large}`}
            src={CorporateTablet}
            alt="home-bg-2-tablet"
          />
          <div
            className={`${Style.AssociationSectionTextContainer.all} ${Style.AssociationSectionTextContainer.small} ${Style.AssociationSectionTextContainer.large}`}
          >
            <h2
              className={`${Style.AssociationSectionTitle.all} ${Style.AssociationSectionTitle.small} ${Style.AssociationSectionTitle.large}`}
            >
              Partner with us for solutions on key areas
            </h2>
            <p
              className={`${Style.AssociationSectionText.all} ${Style.AssociationSectionText.small} ${Style.AssociationSectionText.large}`}
            >
              We create a tailored team to join yours and deliver value. Dig
              deeper into our top categories:
            </p>
            <ul
              className={`${Style.ListContainer.all} ${Style.ListContainer.small} ${Style.ListContainer.large}`}
            >
              {/*todos los li son Links cuando tenga las direcciones, arreglar*/}
              <li className={`${Style.ListText.all}`}>
                <Link
                  className={Style.HoverSpecialPagesLink.all}
                  to="/solutions/digital-transformation-solutions"
                >
                  Digital Transformation Solutions
                </Link>
              </li>
              <li className={`${Style.ListText.all}`}>
                <Link
                  to="/solutions/cloud-operational"
                  className={Style.HoverSpecialPagesLink.all}
                >
                  Cloud Operational Excellence & Upgrades
                </Link>
              </li>
              <li className={`${Style.ListText.all}`}>
                <Link
                  to="/solutions/enterpise-development"
                  className={Style.HoverSpecialPagesLink.all}
                >
                  Enterprise Development
                </Link>
              </li>
              <li className={`${Style.ListText.all}`}>
                <Link
                  to="/solutions/startup-platform-development"
                  className={Style.HoverSpecialPagesLink.all}
                >
                  Startup Platform Development
                </Link>
              </li>
            </ul>
          </div>
        </section>
        <section className={Style.CompanySection.all}>
          <div
            className={`${Style.CompanyDescriptionContainer.all} ${Style.CompanyDescriptionContainer.small} ${Style.CompanyDescriptionContainer.large}`}
          >
            <div
              className={`${Style.CompanyDescriptionTitleContainer.all} ${Style.CompanyDescriptionTitleContainer.small} ${Style.CompanyDescriptionTitleContainer.large}`}
            >
              <h3
                className={`${Style.CompanyDescriptionTitle.all} ${Style.CompanyDescriptionTitle.small} ${Style.CompanyDescriptionTitle.large}`}
              >
                How We Do It (or “Why Build?”)
              </h3>
            </div>
            <div
              className={`${Style.CompanyDescriptionListContainer.all} ${Style.CompanyDescriptionListContainer.small} ${Style.CompanyDescriptionListContainer.large}`}
            >
              <TwoComponentLIST
                titleA="Dedicated Teams"
                contentA="Get undivided attention from our UI/UX experts, Designers, Developers, PMs and Leads/Consultants. Mix and match our resources to fit your needs."
                titleB="Tailored Solutions"
                contentB="No resale of cookie-cutter software: we bake our code from scratch, with value and excellence in mind."
              />
              <TwoComponentLIST
                titleA="Augmented Teams"
                contentA="We provide additional staff to your organization, working remotely, but integrated with your projects and teams."
                titleB="You Own It"
                contentB="We work for you: all the code we deliver, or original design files produced are your property."
              />
              <TwoComponentLIST
                titleA="The Stack you Want"
                contentA="We leverage a full Javascript stack that is cloud-native. Focusing on ReactJS, GraphQL, and NodeJS."
                titleB='Not a "rent-a-dev" Shop'
                contentB="We are not in the business of renting developers by the hour. We assemble skilled product teams to solve your challenges and needs."
              />
            </div>
          </div>
        </section>
        <section
          className={`${Style.AppSectionContainer.all} ${Style.AppSectionContainer.small} ${Style.AppSectionContainer.large}`}
        >
          <div
            className={`${Style.AppSectionTitleContainer.all} ${Style.AppSectionTitleContainer.small} ${Style.AppSectionTitleContainer.large}`}
          >
            <h2
              className={`${Style.AppSectionTitle.all} ${Style.AppSectionTitle.small} ${Style.AppSectionTitle.large}`}
            >
              We tackle B2B, B2C, and enterprise apps
            </h2>
            <div
              className={`${Style.AppSectionTextContainer.all} ${Style.AppSectionTextContainer.small} ${Style.AppSectionTextContainer.large}`}
            >
              <div
                className={`${Style.AppSectionTextContainerA.all} ${Style.AppSectionTextContainerA.small} ${Style.AppSectionTextContainerA.large}`}
              >
                {/*h3 ---> Link, cambiar cuando tenga la url*/}
                <h3
                  className={`${Style.AppSectionSubtitle.all} ${Style.AppSectionSubtitle.small} ${Style.AppSectionSubtitle.large}`}
                >
                    {" "}ProTrak{" "}
                   is an elegant Project Management tool
                </h3>
                <p
                  className={`${Style.AppSectionText.small} ${Style.AppSectionText.large}`}
                >
                  Ideal for General Contractors and anyone in the construction
                  industry.
                </p>
              </div>
            </div>
          </div>
          <ImageContainer
            container={`${Style.AppImg.small} ${Style.AppImg.large}`}
            src={CellphoneHome}
            alt="cellphoneHome"
            className={Style.ImgClassName.all}
          />
        </section>
        <section
          className={`${Style.LastSection.all} ${Style.LastSection.small} ${Style.LastSection.large}`}
        >
          <div
            className={`${Style.LastSectionContainer.all} ${Style.LastSectionContainer.small} ${Style.LastSectionContainer.large}`}
          >
            <div
              className={`${Style.LastSectionTextContainer.all} ${Style.LastSectionTextContainer.small} ${Style.LastSectionTextContainer.large}`}
            >
              <h3
                className={`${Style.LastSectionTitle.all} ${Style.LastSectionTitle.small} ${Style.LastSectionTitle.large}`}
              >
                Let's build something awesome together!
              </h3>
              <ButtonHb
                type="link"
                text="Work with Us"
                to="/contact-us"
                className={`${Style.LastSectionButton.all}`}
              />
            </div>
            <ImageContainer
              container={`${Style.LastSectionImage.all} ${Style.LastSectionImage.small} ${Style.LastSectionImage.large}`}
              src={Blogo}
              alt="B-logo"
            />
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage
